<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout
		v-if="model"
		v-observe-visibility="visibilityChanged"
		class="pointer"
		:column="vertical"
		:row="!vertical"
		align-center
		:justify-center="vertical"
		:justify-start="!vertical"
	>
		<v-flex v-if="showThumbnail" :mb-2="vertical && showTitle" shrink align-center justify-center v-on="{ ...$listeners }" @dragstart="onDragStart($event)">
			<NodeThumbnail
				v-if="hasBeenVisible"
				v-model="model"
				:version="version"
				my-2
				:open="open"
				:is-back-to-parent-folder="isBackToParentFolder"
				:small="small"
				:medium="medium"
				:large="large"
				:do-not-show-subfolders-as-opened="doNotShowSubfoldersAsOpened"
			>
				<template v-slot:topLeftBadge="slotProps">
					<CounterBadge v-if="doShowCounter && !isBackToParentFolder" v-model="node" v-bind="slotProps" />
				</template>
				<template v-slot:topRightBadge="slotProps">
					<ValidationBadge v-if="doShowValidation" v-model="document" :version="version" v-bind="slotProps" />
				</template>
				<template v-slot:overlay="slotProps">
					<SignatureIcon v-model="document" :version="version" :small="small" :medium="medium" :large="large" v-bind="slotProps" />
				</template>
			</NodeThumbnail>
		</v-flex>
		<v-flex
			v-if="hasBeenVisible && showTitle"
			:class="vertical ? 'truncated-title two-lines-text' : 'truncated-title'"
			:shrink="!vertical"
			px-2
			:xs8="search != null && !vertical && !simple"
			:text-xs-center="vertical"
			:text-xs-left="!vertical"
		>
			<v-tooltip bottom allow-overflow>
				<template v-slot:activator="{ on: nameTooltipOn }">
					<span v-on="vertical ? { ...nameTooltipOn } : {}" v-html="itemDisplayName" />
				</template>
				<span v-html="tooltip" />
			</v-tooltip>
		</v-flex>
		<v-flex v-if="search != null && !vertical && !simple" xs4>
			<span v-html="itemLocation" />
		</v-flex>
	</v-layout>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'NodeItem',
	components: {
		NodeThumbnail: () => ({
			component: import('@/components/Documents/Content/NodeThumbnail')
		}),
		CounterBadge: () => ({
			component: import('@/components/Documents/Counter/CounterBadge')
		}),
		ValidationBadge: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationBadge')
		}),
		SignatureIcon: () => ({
			component: import('@/components/Documents/Signature/SignatureIcon')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	props: {
		small: {
			type: Boolean,
			required: false,
			default: false
		},
		medium: {
			type: Boolean,
			required: false,
			default: false
		},
		large: {
			type: Boolean,
			required: false,
			default: true
		},
		simple: {
			type: Boolean,
			required: false,
			default: false
		},
		vertical: {
			type: Boolean,
			required: false,
			default: true
		},
		showCounter: {
			type: Boolean,
			required: false,
			default: true
		},
		showThumbnail: {
			type: Boolean,
			required: false,
			default: true
		},
		showTitle: {
			type: Boolean,
			required: false,
			default: true
		},
		showValidation: {
			type: Boolean,
			required: false,
			default: true
		},
		doNotShowSubfoldersAsOpened: {
			type: Boolean,
			required: false,
			default: false
		},
		isBackToParentFolder: {
			type: Boolean,
			required: false,
			default: false
		},
		open: {
			type: Boolean,
			required: false,
			default: false
		},
		search: {
			type: String,
			required: false,
			default: ''
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: [Object, Array],
			required: false,
			default: null,
			validator: function (value) {
				let result = true
				if (value && !value.hasOwnProperty('name')) {
					result = "missing 'name' property on item"
				}
				return result
			}
		}
	},
	data: function () {
		return {
			hasBeenVisible: false,
			counter: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return Array.isArray(this.model) ? this.model : [this.model]
		},
		node: function () {
			return this.nodes.length == 1 ? this.nodes[0] : null
		},
		documents: function () {
			return this.nodes.length > 1 && this.nodes.every(node => node?.is_document) ? this.nodes : null
		},
		folders: function () {
			return this.nodes.length > 1 && this.nodes.every(node => node?.is_folder) ? this.nodes : null
		},
		mixedNodes: function () {
			return this.nodes.length > 1 && !this.folders && !this.documents ? this.nodes : null
		},
		document: function () {
			return this.node?.is_document ? this.node : null
		},
		folder: function () {
			return this.node?.is_folder ? this.node : null
		},
		itemDisplayName: function () {
			let result
			if (this.isBackToParentFolder) {
				result = this.$t('documents.parent_folder')
			} else {
				result = this.node?.name
				if (this.search) {
					result = this.$highlightMatching(this.node?.name, this.search)
				}
				if (this.search && this.node?.name == result && this.node?.hasOwnProperty('number')) {
					result = this.$highlightMatching(this.node?.number, this.search)
				}
				if (!result) {
					result = this.node?.name
				}
			}
			return result
		},
		itemLocation: function () {
			return this.document?.location
		},
		tooltip: function () {
			let result
			if (this.isBackToParentFolder) {
				result = this.$t('documents.parent_folder')
			} else {
				result = '<b>' + this.node?.name + '</b>'
				if (this.search && this.node?.is_document) {
					result += '<br>' + this.$t('documents.location', { path: this.document.location })
				}
				if (this.node?.created_at) {
					result += '<br>' + this.$t('documents.created_on', { date: this.$translateDateTime(this.node?.created_at, 'long') })
				}
				if (this.node?.updated_at) {
					result += '<br>' + this.$t('documents.modified_on', { date: this.$translateDateTime(this.node?.updated_at, 'long') })
				}
				if (this.node?.is_document && (this.node.latestVersion || this.node.size)) {
					const size = this.node.size
					result += '<br>' + this.$t('documents.file_size', { size: this.$humanReadableSize(parseInt(size)) })
				}
			}
			return result
		},
		currentVersion: function () {
			let result
			if (this.version) {
				result = this.version
			} else if (this.document) {
				result = this.document.latestVersion
			} else {
				result = null
			}
			return result
		},
		doShowCounter: function () {
			return this.showCounter && (!this.model.hasOwnProperty('deleted_at') || this.model.deleted_at == null)
		},
		doShowValidation: function () {
			return this.showValidation && (!this.model.hasOwnProperty('deleted_at') || this.model.deleted_at == null)
		}
	},
	methods: {
		onDragStart: function (event) {
			if (!this.node?.is_folder) {
				event.dataTransfer.effectAllowed = 'move'
				const url = `${this.model?.type}:${this.model?.name}:${this.model?.file}`
				event.dataTransfer.setData('DownloadUrl', url)
			}
		},
		visibilityChanged: function (isVisible) {
			if (!this.hasBeenVisible && isVisible) {
				this.hasBeenVisible = isVisible
			}
		}
	}
}
</script>
<style scoped>
.selectedElement {
	background-color: #e8f0fe;
}
.selectedDarkModeElement {
	background-color: #999999;
}

.truncated-title {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.2em;
	max-height: 2.4em;
	word-break: break-word;
}
.two-lines-text {
	height: 2.4em;
}
</style>
